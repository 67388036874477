import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";

import lozad from 'lozad';

import Plyr from 'plyr';

import.meta.glob(["../fonts/**"]);

// Init Lozad.js
const observer = lozad('.lozad');
observer.observe();

// Init Alpine
window.Alpine = Alpine;

Alpine.plugin(intersect);

Alpine.store("menu", {
    isOpen: false,

    open() {
        this.isOpen = true;
    },

    close() {
        this.isOpen = false;
    },

    toggle() {
        this.isOpen = !this.isOpen;
    },
});

Alpine.start();

// Init Plyr
window.Plyr = Plyr;
